$primary: #8f425f;
$secondary: #697FB6;
$off: #8da9d0;
$light: #eaf2ff;
$background: #f4f8ff;

$danger: #f63c3c;
$warning: #ffa500;
$success: #32cd32;

$white: #fff;
$white-75: rgba($white, .75);
$black: #050c1a;
$black-75: rgba($black, .75);
$black-50: rgba($black, .50);
$black-25: rgba($black, .25);
$black-10: rgba($black, .10);
$transparent: rgba($white, 0);

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "danger": $danger,
    "off": $off,
    "light": $light,
);

$navbar-height: 96px;


$border-radius: 4px;
$spacer: 16px;


// $input-btn-padding-y: $spacer * .75;
// $input-btn-padding-x: $spacer;