h1, h2, h3, h4, h5 {
    font-family: "Eudoxus Sans";
    font-weight: bold;
    margin-bottom: 0;
}

.text-xl, .text-lg, .text-md, .text-sm, .text-xs {
    font-family: "Comfortaa";
    font-weight: normal;
}

.link-1, .link-2 {
    font-family: "Martel Sans";
    font-weight: normal;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &.active {
        font-weight: bold;
    }
}

em {
    font-weight: bold;
    font-style: normal;
    color: $primary;
}

$sizes:
    "h1" 36px 44px,
    "h2" 24px 32px,
    "h3" 20px 28px,
    "h4" 16px 24px,
    "h5" 14px 22px,
    ".text-xl" 14px 22px,
    ".text-lg" 12px 16px,
    ".text-md" 10px 12px,
    ".text-sm" 10px 12px,
    ".text-xs" 8px 10px,
    ".link-1" 14px 22px,
    ".link-2" 12px 16px;

@include media-breakpoint-up(lg) {
    $sizes:
        "h1" 48px 56px,
        "h2" 36px 44px,
        "h3" 24px 32px,
        "h4" 20px 28px,
        "h5" 16px 24px,
        ".text-xl" 16px 24px,
        ".text-lg" 14px 22px,
        ".text-md" 12px 16px,
        ".text-sm" 10px 12px,
        ".text-xs" 8px 10px,
        ".link-1" 16px 24px,
        ".link-2" 12px 16px;
}

@each $name, $size, $height in $sizes {
    #{$name} {
        font-size: $size;
        line-height: $height;
    }
}