@import './fonts';
@import './variables';
@import '~bootstrap/scss/bootstrap';

$sizes: 0, 25, 50, 75, 100;
@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size in $sizes {
        @include media-breakpoint-up($breakpoint) {
            .w#{$infix}-#{$size} {
                width: #{$size + '%'} !important;
            }
            .h#{$infix}-#{$size} {
                height: #{$size + '%'} !important;
            }
        }
    }
}

@import './animations';
@import './typography';
@import './navbar';
@import './bento';


body {
    @extend .text-lg;
    color: $black;
    background-color: $background;
}

section {
    padding: 100px 0;

    @include media-breakpoint-up(lg) {
        padding-top: 180px;
    }

    &#hero {
        @extend .text-xl;
        width: 100vw;
        height: calc(100vh - (#{$navbar-height} / 2));
        overflow: hidden;
        padding-top: 160px;

        #soon {
            margin-top: 50px;
            @include media-breakpoint-up(md) {
                margin-top: 110px;
            }
            @include media-breakpoint-up(lg) {
                margin-top: 160px;
            }
        }

        &::before {
            content: "";
            z-index: -1;
            width: 100%;
            height: calc(100vh + (#{$navbar-height} * 1.5));
            position: absolute;
            top: $navbar-height * -2;
            background-image: url('./imgs/hero-background.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0 0;
        }

        & > .container {
            position: relative;
            height: 100%;

            @include media-breakpoint-up(md) {
                &::after {
                    content: "";
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    background-image: url('./imgs/post.svg');
                    background-repeat: no-repeat;
                    background-size: 50%;
                    background-position: right 0 top 0;
                }
            }
        }
    }

    #scroller {
        position: relative;
        width: fit-content;
        margin: 0 auto;
        animation: bounce 1s infinite alternate;
    }

    #hero-banner {
        $height: 180px;
        $gap: 64px;

        color: $white;
        height: $height;
        border-radius: 12px;
        background-color: $secondary;
        gap: $gap;
        padding: ($gap / 2) $gap;
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
        position: absolute;
        bottom: -1 * ($height / 2);

        .item {
            text-align: center;
        }
    }

    &#bento {
        .bento-container {
            &, .row {
                --bs-gutter-x: 16px;
                --bs-gutter-y: 16px;
            }

            width: 100%;
            margin: 0;

            .bento-item {
                $height: 240px;

                width: 100%;
                height: $height;
                padding: 16px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: $white;
                border-radius: 8px;
                overflow: hidden;

                @include media-breakpoint-up(lg) {
                    height: $height / 2;

                    &.h-2x {
                        height: $height + 16px !important;
                    }
                }

                & > *:not(img) {
                    z-index: 2;
                }

                h1 {
                    margin-bottom: .33em;
                }
                h2 {
                    margin-bottom: .25em;
                }

                img {
                    position: absolute;
                }

                &:nth-of-type(1) {
                    border: 2px solid red;
                }
            }
        }
    }
}

footer {
    @extend .text-sm;
    color: $off;
    padding: 60px 0;

    a {
        @extend .link-2;
        color: $off;
    }
}