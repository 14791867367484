.bento-layout {
    $gap: $spacer / 2; // overwrite bootstrap's default value

    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(#{100% / $grid-columns} - #{$gap}), #{$grid-columns}fr));
    grid-gap: $gap;
    grid-auto-flow: dense;
    grid-auto-rows: minmax(80px, 1fr);

    @include media-breakpoint-up(lg) {
        grid-auto-rows: minmax(120px, 1fr);
    }

    .bento-item {
        padding: $spacer;
        border-radius: $gap;
        background-color: $white;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-x: hidden;

        & > * {
            z-index: 2;
        }

        @each $breakpoint in map-keys($grid-breakpoints) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @for $i from 1 through $grid-columns {
                @include media-breakpoint-up($breakpoint) {
                    &.w#{$infix}-#{$i} {
                        grid-column-end: span #{$i};
                    }
                    &.h#{$infix}-#{$i} {
                        grid-row-end: span #{$i};
                    }
                }
            }
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-repeat: no-repeat;
        }
        &:nth-child(1) {
            &:before {
                background-image: url('./imgs/feedback.svg');
                background-size: auto 100px;
                background-position: right -10px bottom 16px;

                @include media-breakpoint-up(lg) {
                    background-size: auto 120px;
                    background-position: right -8px bottom 40px;
                }
            }
        }
        &:nth-child(2) {
            justify-content: end;
            @include media-breakpoint-up(lg) {
                justify-content: center;
            }

            &:before {
                background-image: url('./imgs/accountability.svg');
                background-size: auto 120px;
                background-position: right 16px top;

                @include media-breakpoint-up(md) {
                    // background-size: auto 120px;
                    background-position: right -16px top;
                }

                @include media-breakpoint-up(lg) {
                    // background-size: auto 120px;
                    background-position: right top;
                }
            }
        }
        &:nth-child(3) {
            justify-content: end;
            @include media-breakpoint-up(lg) {
                justify-content: center;
            }

            &:before {
                background-image: url('./imgs/learning.svg');
                background-size: auto 100px;
                background-position: right 8px top 8px;

                @include media-breakpoint-up(lg) {
                    background-size: auto 100px;
                    background-position: right 8px center;
                }
            }
        }
        &:nth-child(4) {
            &:before {
                background-image: url('./imgs/marketing.svg');
                background-size: auto 120px;
                background-position: right 8px bottom;

                @include media-breakpoint-up(lg) {
                    background-size: auto 220px;
                    background-position: right 32px center;
                }
            }
        }
        &:nth-child(5) {
            @include media-breakpoint-up(lg) {
                justify-content: end;
            }
            &:before {
                background-image: url('./imgs/networking.svg');
                background-size: auto 100px;
                background-position: right 8px bottom 16px;

                @include media-breakpoint-up(lg) {
                    background-size: auto 120px;
                    background-position: right 16px top 16px;
                }
            }
        }
    }
}