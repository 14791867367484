@font-face {
    font-family: 'Eudoxus Sans';
    font-weight: bold;
    src: local('EudoxusSans-Bold'), url('./fonts/EudoxusSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Martel Sans';
    font-weight: bold;
    src: local('MartelSans-Bold'), url('./fonts/MartelSans-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Martel Sans';
    font-weight: normal;
    src: local('MartelSans-Regular'), url('./fonts/MartelSans-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Comfortaa';
    font-weight: bold;
    src: local('Comfortaa-SemiBold'), url('./fonts/Comfortaa-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Comfortaa';
    font-weight: normal;
    src: local('Comfortaa-Regular'), url('./fonts/Comfortaa-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Comfortaa';
    font-weight: light;
    src: local('Comfortaa-Light'), url('./fonts/Comfortaa-Light.ttf') format('truetype');
}